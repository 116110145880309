<template>
  <!-- 新闻资讯详情 -->
  <div class="main-contain">
    <div class="head">
      <!-- <div class="head-title">新闻资讯详情</div>
      <div class="returnbt" @click="backbt">返回</div> -->
    </div>
    <!-- <div class="line"></div> -->
    <div class="maintitle">
       {{infolist.title}}
    </div>
    <div class="timecontain">
      <!-- <el-image class="elimage" :src="icon1" /> -->
      <div class="time">时间：{{infolist.updated_at}}</div>
    </div>
    <div class="tips">
      {{infolist.sub_title}}
    </div>
    <div class="maintext">
     <div v-html="infolist.content"></div>
    </div>
    <div class="appendix">
      <span class="text1">附件:</span>
      <span class="text2">{{infolist.file}}</span>
    </div>
    
  </div>
</template>
<script>
import dayjs from "dayjs";
import { getInfo } from "@/api/news";
export default {
  name: "courselearning",
  components: {},
  data() {
    return {
      infolist: {},
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/时间 钟表.png"),
    };
  },
  created() {
    this.getinfolist({ id: parseInt(this.$route.query.id) });
  },
  methods: {
    backbt() {
      // this.$router.push({
      //   path: "/home/news/mainpage",
      // });
      // 首页点击进来的-返回首页
      // 列表点击进来的-返回列表
      this.$router.back()
    },
    getinfolist(params) {
      getInfo(params)
        .then((response) => {
          if (response.data) {
            this.infolist = {
              content: response.data.content,
              cover: response.data.cover,
              id: response.data.id,
              is_top:response.data.is_top,
              sub_title: response.data.sub_title,
              title: response.data.title,
              file:response.data.file,
              updated_at: dayjs
                .unix(response.data.updated_at)
                .format("YYYY-MM-DD HH:mm:ss"),
            };
          }
        })
        .catch((error) => {
        });
    },
  },
};
</script>
<style scoped lang="scss">

.main-contain {
  margin-bottom: 20px;
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-title {
      font-size: 20px;
 
      font-weight: 400;
      color: #3D84FF;
      user-select: none;
      white-space: nowrap;
    }
    .returnbt {
      width: 88px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #3D84FF;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      user-select: none;
      cursor: pointer;
    }
  }
  .line {
    margin-top: 14px;
    // margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maintitle {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #222222;
  }
  .tips {
    box-sizing: border-box;
    padding: 20px;
    padding-left: 40px;
    height: 98px;
    background: #f7f7f7;
    font-size: 15px;
    font-weight: 400;
    color: #666666;
  }

  .maintext {
    padding: 20px;
    padding-left: 40px;
    font-size: 15px;
    font-weight: 400;
    color: #666666;
  }
  .appendix {
    padding-left: 40px;
    .text1 {
      font-size: 15px;
      font-weight: 400;
      color: #666666;
    }
    .text2 {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #3d84ff;
    }
  }
  .timecontain {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    .elimage {
      width: 13px;
      height: 13px;
    }
    .time {
      margin-left: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #999;
    }
  }
}
</style>
